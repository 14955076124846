import React from 'react'
import ChatWidgetV2 from '../components/ChatWidgetV2'

// eslint-disable-next-line
export default function () {
    return (
        <div style={{width: '100%'}}>
            <ChatWidgetV2
                defaultOpen={true}
                fullScreenMode={true}
                showCloseButton={false}
            />
        </div>
    )
}
