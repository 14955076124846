import { createTheme } from '../library/pld-ui';
import chatWidgetThemeOptions from './chatWidgetDefault'
import appThemeOption from './appDefault'

/// this theme is for ChatWidget
const chatWidgetTheme = () => createTheme(chatWidgetThemeOptions)

//// theme for complete app
const appTheme = () => createTheme(appThemeOption)

const buildChatTheme = (clientTheme) => {
    if (clientTheme) {
        const clientThemeOptions = {
            palette: {
                primary: {
                    main: clientTheme.primaryColor || chatWidgetThemeOptions.palette.primary.main,
                },
                secondary: {
                    main: clientTheme.secondaryColor || chatWidgetThemeOptions.palette.secondary.main,
                },
                text: {
                    primary: clientTheme.primaryText || chatWidgetThemeOptions.palette.text.primary,
                },
            },
            typography: {
                fontFamily: clientTheme.fontFamily || chatWidgetThemeOptions.typography.fontFamily,
                htmlFontSize: parseInt(clientTheme.fontSize) || chatWidgetThemeOptions.typography.htmlFontSize,
                fontSize: parseInt(clientTheme.fontSize) || chatWidgetThemeOptions.typography.fontSize,
            },
            shape: {
                borderRadius: parseInt(clientTheme.borderRadius) || chatWidgetThemeOptions.shape.borderRadius,
            }
        }

        return createTheme(clientThemeOptions)
    }

    return chatWidgetTheme()
}

export { appTheme, chatWidgetTheme, buildChatTheme }