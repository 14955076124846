import React from 'react'

import {
    GridWrapper,
    NavigationDrawerContentBuilder,
    TryIcon, HubIcon, SettingsIcon, DashboardIcon, SpeakerNotesIcon, PeopleIcon, FolderSharedIcon, QuizIcon,
} from '../../library/pld-ui'

import {
    AuthProtectedPage,
    AuthenticatedAppBar,
} from '../../library/pld-auth-ui'

// import {default as ChatWidget} from '../../library/PLD-Chat-Widget'

import ChatStatisticsCard from '../../components/AdminReports/ChatStatisticsCard'
import JMetricsCard from '../../components/AdminReports/JMetricsCard'
import TracerlinkStatisticsCard from '../../components/AdminReports/TracerlinkStatisticsCard'
import MissedNLUTable from '../../components/AdminReports/MissedNLUTable'
import MissedNLUTableV2 from '../../components/AdminReports/MissedNLUTableV2';
import NewWidgetTester from '../../components/AdminReports/NewWidgetTester';
import RoomManagement from '../../components/AdminReports/RoomManagement';
import UserManagement from '../../components/AdminReports/UserManagement';
import AskedQuestions from '../../components/AdminReports/AskedQuestions';
import GraphHelper from '../../components/AdminReports/GraphHelper';
import TicketReport from '../../components/AdminReports/TicketReports';
import ButtonClickReport from '../../components/AdminReports/ButtonClickReport';

// eslint-disable-next-line
export default function Admin() {

    const componentMap = {
        'default': {
            title: "Dashboard",
            icon: <DashboardIcon />,
            component: <DashboardBase />,
        },
        '#Dashboard': {
            title: "Dashboard",
            icon: <DashboardIcon />,
            component: <DashboardBase />,
        },
        '#MissedNLUTable': {
            title: "Missed NLU Table",
            component: <MissedNLUTable />,
        },
        '#MissedNLUTableV2': {
            title: "Missed NLU Table V2",
            component: <MissedNLUTableV2 />,
        },
        "#NewWidgetTester": {
            title: "Widget Tester",
            component: <NewWidgetTester />,
        },
        "#RoomManagement": {
            title: "Room Management",
            component: <RoomManagement />,
        },
        "#ButtonClickReport": {
            title: "Button Click",
            component: <ButtonClickReport/>,
        },
        "#Users": {
            title: "User Management",
            component: <UserManagement />,
        },
        "#AskedQuestions": {
            title: "Asked Questions",
            component: <AskedQuestions />,
        },
        "#GraphHelper": {
            title: "Graph Helper",
            component: <GraphHelper />,
        },
        "#Tickets": {
            title: "Tickets",
            component: <TicketReport />,
        },
    }

    const [componentToRender, setComponentToRender] = React.useState(componentMap[window.location.hash] || componentMap['default'])

    const drawerBarTemplate = [
        {
            title: 'Dashboard',
            icon: <DashboardIcon />,
            onClick: () => { window.location.hash = null  }
        },
        {
            title: 'Settings',
            icon: <SettingsIcon />,
            onClick: () => { window.location.hash = '#Settings' }
        },
        {
            title: 'NLU Report',
            icon: <SpeakerNotesIcon />,
            onClick: () => { window.location.hash = '#MissedNLUTable' }
        },
        {
            title: 'NLU Report V2',
            icon: <SpeakerNotesIcon />,
            onClick: () => { window.location.hash = '#MissedNLUTableV2' }
        },
        {
            title: 'New Widget Tester',
            icon: <TryIcon />,
            onClick: () => { window.location.hash = '#NewWidgetTester' }
        },
        {
            title: 'Room Management',
            icon: <FolderSharedIcon />,
            onClick: () => { window.location.hash = '#RoomManagement' }
        },
        {
            title: 'Button Click',
            icon: <FolderSharedIcon />,
            onClick: () => { window.location.hash = '#ButtonClickReport' }
        },
        {
            title: 'User Management',
            icon: <PeopleIcon />,
            onClick: () => { window.location.hash = '#Users' }
        },
        {
            title: 'Asked Questions',
            icon: <QuizIcon />,
            onClick: () => { window.location.hash = '#AskedQuestions' }
        },
        {
            title: 'Graph Helper',
            icon: <HubIcon />,
            onClick: () => { window.location.hash = '#GraphHelper' }
        },
        {
            title: 'Tickets',
            icon: <HubIcon />,
            onClick: () => { window.location.hash = '#Tickets' }
        },
    ]
    const drawerContent = NavigationDrawerContentBuilder({ drawerContent: drawerBarTemplate })

    React.useEffect(() => {
        window.addEventListener('hashchange', () => {
            console.log("new window hash: " + window.location.hash)
            setComponentToRender(componentMap[window.location.hash] || componentMap['default'])
        })
        // eslint-disable-next-line
    }, [])
    


    return (
        <AuthProtectedPage>
            <AuthenticatedAppBar
                title={componentToRender.title}
                drawerContent={drawerContent}
            />
            {componentToRender.component}
        </AuthProtectedPage>
    )
}


function DashboardBase(props) {
    return (
        <GridWrapper>
            <ChatStatisticsCard />
            <TracerlinkStatisticsCard />
            <JMetricsCard />
        </GridWrapper>
    )
}

