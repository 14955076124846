import React from "react";

import { default as ConversationMessages } from "../ConversationMessages";
import { default as ConversationHeader } from "../ConversationHeader";
import { default as ConversationInput } from "../ConversationInput";

export default function Conversation(props) {
  const conversationContainerStyle = {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  };
  return (
    <>
      <div style={conversationContainerStyle}>
        <ConversationHeader />
        <ConversationMessages />
        <ConversationInput />
      </div>
    </>
  );
}
