import React from "react";
import {
  Typography,
} from "@mui/material";

import { 
  DefaultStyles,
  SkeletonLogo,
} from "../../../pld-ui";


import { useAuthFunctions } from "../../../pld-auth";

export default function AuthenticatedOrganizationLogo(props) {
  const classes = DefaultStyles();
  const auth = useAuthFunctions()
  const [logo, setLogo] = React.useState(null);

  React.useEffect(() => {
    auth.GetOrganizationLogo().then((result) => {
      var objectURL = URL.createObjectURL(result);
      setLogo(objectURL)
    }).catch((error) => {
      // console.log("error", error)
    })
    // Do not add auth as a dependency. This will cause an infinite loop.
    // eslint-disable-next-line
  }, []);

  return (
    <>
    {logo ? (
      <Typography variant="body2" weight="medium" className={classes.logotype}>
      <img alt="logo" className={classes.logotypeImage} src={logo} />
    </Typography>
    ) : (
      <SkeletonLogo/>
    )}
    </>
    

  );
}
