import React from 'react'
import { GridTileItem, Button, Typography } from '../..'

export default function GridButton(props) {
    const buttonContents = props.text || props.children || "Button"
    return (
        <GridTileItem>
            <Button variant="contained" {...props}>
                <Typography>{buttonContents}</Typography>
            </Button>
        </GridTileItem>
    )
}

