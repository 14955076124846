import React from "react";

import { useChatSettings } from "../..";

import { default as ConversationButtons } from "../ConversationButtons";
import { default as ConversationTextInput } from "../ConversationTextInput";

export default function ConversationInput(props) {
  const chatSettings = useChatSettings();

  const inputContainerStyle = {
    borderBottomLeftRadius: chatSettings.borderRadius,
    borderBottomRightRadius: chatSettings.borderRadius,
    // bottom: 0,
    width: "100%",
    // height: chatSettings.inputContainerHeight,
    background: chatSettings.inputContainerBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    alignSelf: 'flex-end',
    flexDirection: 'column',
  };

  return (
    <>
      {chatSettings.displayInput && (
        <div style={inputContainerStyle}>
          <ConversationButtons />
          <ConversationTextInput />
        </div>
      )}
    </>

  );
}
