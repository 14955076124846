

export function GenericReducer(state, action) {
    switch (action.type) {
        case reducerActions.SET_STATE_VALUE:
            return onSetContextStateValueRequest(state, action)
        case reducerActions.UPDATE_STATE_MAP:
            return onUpdateContextStateMapRequest(state, action)
        case reducerActions.REMOVE_STATE_VALUE:
            return onRemoveContextStateValueRequest(state, action)
        case reducerActions.REMOVE_STATE_VALUES:
            return onRemoveMultipleContextStateValuesRequest(state, action)
        default:
            return state;
    }
}
const reducerActions = {
    SET_STATE_VALUE: "SET_STATE_VALUE",
    UPDATE_STATE_MAP: "UPDATE_STATE_MAP",
    REMOVE_STATE_VALUE: "REMOVE_STATE_VALUE",
    REMOVE_STATE_VALUES: "REMOVE_STATE_VALUES",
}

function SetContextStateValue(dispatch, key, value) {
    dispatch({
        dispatch: dispatch,
        type: reducerActions.SET_STATE_VALUE,
        key: key,
        value: value,
    })
}
function onSetContextStateValueRequest(state, action) {
    return {
        ...state,
        [action.key]: action.value
    };
}
function UpdateContextStateMap(dispatch, updateMap) {
    dispatch({
        dispatch: dispatch,
        type: reducerActions.UPDATE_STATE_MAP,
        updateMap: updateMap,
    })
}
function onUpdateContextStateMapRequest(state, action) {
    return {
        ...state,
        ...action.updateMap
    };
}
function RemoveContextStateValue(dispatch, key) {
    dispatch({
        dispatch: dispatch,
        type: reducerActions.REMOVE_STATE_VALUE,
        key: key,
    })
}
function onRemoveContextStateValueRequest(state, action) {
    var newState = { ...state };
    delete newState[action.key];
    return newState;
}
function RemoveMultipleContextStateValues(dispatch, keys) {
    dispatch({
        dispatch: dispatch,
        type: reducerActions.REMOVE_STATE_VALUES,
        keys: keys,
    })
}
function onRemoveMultipleContextStateValuesRequest(state, action) {
    var newState = { ...state };
    action.keys.forEach((key) => {
        delete newState[key];
    });
    return newState;
}
// #endregion


export function GenericReducerFunctionMap(dispatch) {
    const functions = {
        SetContextStateValue: (key, value) => SetContextStateValue(dispatch, key, value),
        UpdateContextStateMap: (updateMap) => UpdateContextStateMap(dispatch, updateMap),
        RemoveContextStateValue: (key) => RemoveContextStateValue(dispatch, key),
        RemoveMultipleContextStateValues: (keys) => RemoveMultipleContextStateValues(dispatch, keys),
    }
    return functions
}
