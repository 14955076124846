import { createContext } from 'react';

const HostConfigContext = createContext() //// context for Adaptive Card, Stores desing data derived from MUI theme
const ClientTheme = createContext() //// Theme data for Chat Widget
const BotConfig = createContext({
    chatMode: 'default',
    quickButtonsStacked: true,
    enableLoader: false
}) /// Chat Mode for direct to live agent or use nlp first

export {
    HostConfigContext,
    ClientTheme,
    BotConfig
}