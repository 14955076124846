import * as React from 'react';

import { useAuthFunctions } from '../../../library/pld-auth'
import * as utils from '../../../utils'
import {
    MaterialReactTable,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFullScreenButton,
} from 'material-react-table';
import { Box, Tab, IconButton } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';



export default function ButtonClickReport(props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [reportButtonClikcData, setButtonclickReportData] = React.useState([])
    const [value, setValue] = React.useState('1');
    const [reportLocationClikcData, setLocationclickReportData] = React.useState([])
    const [columns, setColumns] = React.useState([])
    const [columnsuser, setColumnsuser] = React.useState([])
    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleExportData = () => {


        const csv = generateCsv(csvConfig)(reportButtonClikcData);
        download(csvConfig)(csv);
    };
    const handleLocationExportData = () => {
        let col = ["month"]
        columnsuser.forEach((column) => {
            if(column.header !== "month"){
                col.push(column.header)
            }
            
        })
        let tempData = []
        reportLocationClikcData.forEach((row) => {
            let obj = {}
            col.forEach((key) => {
                 
                obj[key] = row[key]
            })
            tempData.push(obj)
        })
        let Config = mkConfig({
            fieldSeparator: ',',
            decimalSeparator: '.',
            useKeysAsHeaders: true,
        });
        const csv = generateCsv(Config)(tempData);
        download(csvConfig)(csv);
    };

    const processDataResult = React.useCallback((data) => {
        if (data instanceof Array === true) {
            let monts_col = [{ header: "month", accessorKey: "month", enableGrouping: false }]
            let columns = data[0].button_strings.map((key) => {
                return {
                    header: key,
                    accessorKey: key,
                    enableGrouping: false, //do not let this column be grouped
                }
            })

            let button_click_data = []
            data.forEach((row) => {
                let team_row = { month: row.month }
                row.button_strings.forEach((key) => {
                    team_row[key] = row[key]
                    delete row[key]
                })
                button_click_data.push(team_row)
                delete row["button_strings"]
                delete row["id"]
            })
            let columnsuserloc = []
            data.forEach((row) => {
                let keys = Object.keys(row)
                keys.forEach((key) => {
                    if (!columnsuserloc.includes(key)) {
                        columnsuserloc.push(key)
                    }
                })
            })

            let columnsuservalues = [{ header: "month", accessorKey: "month", enableGrouping: false }]
            columnsuserloc.forEach((key) => {
                if (key !== "month") {
                    columnsuservalues.push({
                        header: key,
                        accessorKey: key,
                        enableGrouping: false, //do not let this column be grouped
                    })
                }
            })
            setColumnsuser(columnsuservalues)
            setColumns(monts_col.concat(columns))
            setButtonclickReportData(button_click_data)
            setLocationclickReportData(data)

        } else {
            setButtonclickReportData([])
            setLocationclickReportData([])
        }
        setLoading(false)

    }, [setLoading, setButtonclickReportData, setLocationclickReportData])


    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/buttonclickreport`, "POST", {}).then((result) => {
            // console.log("rooms_initial", result)
            processDataResult(result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Button and User Click Data Report">
                        <Tab label="Button Click Data" value="1" />
                        <Tab label="User Click Data" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <div>
                        <MaterialReactTable
                            columns={columns}
                            data={reportButtonClikcData || []}

                            renderToolbarInternalActions={({ table }) => (
                                <Box>
                                    {/* add custom button to print table  */}
                                    <IconButton
                                        onClick={handleExportData}
                                    >
                                        <FileDownloadIcon />
                                    </IconButton>
                                    {/* along-side built-in buttons in whatever order you want them */}
                                    <MRT_ToggleDensePaddingButton table={table} />
                                    < MRT_ToggleFullScreenButton table={table} />
                                </Box>
                            )}
                            enableColumnResizing
                            enableStickyHeader
                            enableColumnFilterModes
                            enableStickyFooter
                            state={{ showProgressBars: false }} //or showSkeletons
                            initialState={{
                                density: 'compact',
                                showColumnFilters: false,
                                expanded: true, //expand all groups by default
                                pagination: { pageIndex: 0, pageSize: 200 },
                                sorting: [{ id: 'month', desc: true }],
                                columnPinning: { left: ['month'] }
                            }}
                            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                            enablePinning
                            muiTableContainerProps={{}}
                        />


                    </div>

                </TabPanel>
                <TabPanel value="2">
                    <div>
                        <MaterialReactTable
                            columns={columnsuser || []}
                            data={reportLocationClikcData || []}

                            renderToolbarInternalActions={({ table }) => (
                                <Box>
                                    {/* add custom button to print table  */}
                                    <IconButton
                                        onClick={handleLocationExportData}
                                    >
                                        <FileDownloadIcon />
                                    </IconButton>
                                    {/* along-side built-in buttons in whatever order you want them */}
                                    <MRT_ToggleDensePaddingButton table={table} />
                                    < MRT_ToggleFullScreenButton table={table} />
                                </Box>
                            )}
                            enableColumnResizing
                            enableStickyHeader
                            enableColumnFilterModes
                            enableStickyFooter
                            state={{ showProgressBars: false }} //or showSkeletons
                            initialState={{
                                density: 'compact',
                                showColumnFilters: false,
                                expanded: true, //expand all groups by default
                                pagination: { pageIndex: 0, pageSize: 200 },
                                sorting: [{ id: 'month', desc: true }],
                                columnPinning: { left: ['month'] }

                            }}

                            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                            enablePinning
                            muiTableContainerProps={{}}
                        />


                    </div>



                </TabPanel>
            </TabContext>
        </Box>
    );
}

