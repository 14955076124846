import React from "react";
import {
  IconButton,
} from "@mui/material";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as CancelIcon,
} from "@mui/icons-material";
import classNames from "classnames";

import { DefaultStyles } from "../..";
import { callIfFunction } from "../../../pld-helpers";

export default function ToggleButtons(props) {
  const classes = DefaultStyles();
  const TrueIcon = props.trueIcon || <CheckCircleOutlineIcon />;
  const FalseIcon = props.falseIcon || <CancelIcon />;

  const iconProps = {
    classes: {
      root: classNames(
        classes.headerIcon,
        classes.headerIconCollapse,
      )
    }
  }

  const onClick = (event) => {
    callIfFunction(props.onClick, event);
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={onClick}
        className={classNames(
          classes.headerMenuButton,
          classes.headerMenuButtonCollapse,
        )}
        size="large">
        {props.defaultState ? (
          <>
            {React.cloneElement(FalseIcon, iconProps)}
          </>
        ) : (
          <>
            {React.cloneElement(TrueIcon, iconProps)}
          </>
        )}
      </IconButton>
    </>
  );
}
