import React from 'react';

import ChatWidgetV2 from '../components/ChatWidgetV2'

const mystyle = {
    pointerEvents: 'auto',
    zIndex: 0,
}

// eslint-disable-next-line
export default function () {

    const getContext = () => {
        let context = {};
        try {
            if (window.$ctx) {
                context.upn = window.$ctx.pageContext._user.loginName;
            }
            context = {
                ...context,
            }
            context.page = "widget"
        } catch (error) {
            console.log("error getting context:", error);
        }
        
        return context;
    }

    return (
        <ChatWidgetV2 
            style={mystyle}
            getContext={getContext}
        />
    )
}
