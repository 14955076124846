import { 
    Card, CardContent, CardHeader, 
    Divider, Typography, useTheme, SkeletonCard 
} from '../../../pld-ui';



export default function DisplayCard(props) {
    const loading = props.loading || false

    const theme = useTheme();

    return (
        <> {loading ? (
            <SkeletonCard />
        ) : (
         <Card
                sx={{
                    border: 'none',
                    borderColor: theme.palette.primary.dark,
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    }
                }}
            >
                {/* card header and action */}
                {props.title && <CardHeader 
                    sx={{
                        '& .MuiCardHeader-action': { mr: 0 },
                        backgroundColor: theme.palette.primary.main,
                    }} 
                    title={<Typography variant='h6'>{props.title}</Typography>} 
                />}

                {/* content & header divider */}
                {props.title && <Divider />}

                {/* card content */}
                <CardContent 
                    sx={{
                        // backgroundColor: theme.palette.primary.light,
                    }}
                >
                    {props.children}
                </CardContent>
            </Card>
        )}
        </>
       
    )
}
