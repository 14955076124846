import { useRef } from 'react';

// This effectively wraps the useImperativeHandle hook to make it easier to use
// The intent is let the user just pass an instance of a controller and have easy completion
// for all the functions I want to expose. While allowing for an additional customization point.
export default function ChatController(props) {
  var controller = useRef();
  return ({
    ref: controller,
    LogController: () => { console.log( "Controller", controller.current )},
    ToggleChatWindow: () => { controller.current.ToggleChatWindow() },
    OpenChatWindow: () => { controller.current.setConfigValue("isOpen", true) },
    CloseChatWindow: () => { controller.current.setConfigValue("isOpen", false) },
    hideHeader: () => { controller.current.setConfigValue("displayHeader", false) },
    showHeader: () => { controller.current.setConfigValue("displayHeader", true) },
    
    setConfigValue: (key, value) => { controller.current.setConfigValue(key, value) },
  })
}

