import PropTypes from 'prop-types';
import { useMediaQuery, styled } from '../library/pld-ui'

const ThemedChatWidget = styled('div', {shouldForwardProp: false})(function ({ theme, subtitle, fullScreenMode, headerVisibility, stackQuickactionButtons }) {
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const mediumScreen = useMediaQuery(theme.breakpoints.down('md'))
    let customBorderRadius = fullScreenMode || smallScreen ? 0 : theme.shape.borderRadius;

    return {
        '& *': { 
            fontFamily: theme.typography.fontFamily
        },
        '& .loader-container': {
            background: theme.palette.primary.main,
            '& .loader-dots': {
                background: theme.palette.text.primary,
            },
        },
        '& .rcw-widget-container': {
            maxWidth: fullScreenMode || smallScreen ? '100%' : '370px',
            maxHeight: fullScreenMode || mediumScreen ? '100%' : 'unset',
            height: fullScreenMode || smallScreen || mediumScreen ? '100%' : 'max-content',
        },
        '& .rcw-title': {
            padding: `0 0 ${subtitle ? 10 : 0}px !important`
        },
        '& .rcw-input': {
            /// setting font size to minimum 16px because font size lower than this will force iphone devices to zoom in
            fontSize: theme.typography.fontSize > 16 ? `${theme.typography.fontSize}px` : '16px !important',
            cursor: 'text'
        },
        '& .rcw-sender': {
            msFlexAlign: 'center',
            alignItems: 'center',
            padding: 5,
            borderRadius: `0 0 ${customBorderRadius}px ${customBorderRadius}px`,

            //// Force removing emoji icons, 
            //// this seems like an error from react-chat-widget, 
            //// disable flag isn't working
            '& .rcw-picker-btn': {
                display: 'none'
            },
            '& .rcw-new-message': {
                width: 'calc(100% - 50px)'
            }
        },
        '& .rcw-conversation-container': {
            minWidth: 'unset',
            maxWidth: fullScreenMode || smallScreen ? '100%' : '90vw',
            borderRadius: `${theme.shape.borderRadius}px !important`,
            '& .rcw-header': {
                padding: `15px 0 15px !important`,
                display: headerVisibility === false ? 'none' : 'flex',
                background: theme.palette.primary.main,
                borderRadius: `${customBorderRadius}px ${customBorderRadius}px 0 0`
            },
            '& .rcw-close-button': {
                background: theme.palette.primary.main,
            },
        },
        '& .rcw-launcher': {
            background: theme.palette.primary.main
        },
        '& .quick-buttons-container': {
            overflowX: 'auto',
            position: 'relative',
            flexShrink: 0,
            //// Button stacked property, this is to support quick action button as stacked view
            //// Future Ravi, don't get angry when you see this
            '& .quick-buttons': {
                display: stackQuickactionButtons ? 'flex' : 'block',
                flexDirection: stackQuickactionButtons ? 'row !important': 'unset',
                alignItems: stackQuickactionButtons ? 'stretch': 'unset',
                flexWrap: 'wrap !important',
                '& .quick-list-button': {
                    display: stackQuickactionButtons ? 'flex' : 'inline-block',
                    alignItems: stackQuickactionButtons ? 'center': 'unset',
                    justifyContent: stackQuickactionButtons ? 'center': 'unset',
                    flex: '0 1 !important',

                    /// Now lets make buttons shrink a bit so we can fit maximum in 2 rows
                    marginRight: stackQuickactionButtons ? '5px': '10px',
                    marginBottom: stackQuickactionButtons ? '3px': '0',
                    '& .quick-button': {
                        padding: stackQuickactionButtons ? '5px 8px': '5px 10px',
                    }
                },
            }
        },
        '& .quick-button': {
            border: `2px solid ${theme.palette.primary.main}`,
            '&:active': {
                background: theme.palette.primary.main,
                color: theme.palette.text.primary
            }
        },
        '& .rcw-snippet': {
            borderRadius: `${theme.shape.borderRadius}px !important`
        },

        //// primary - light
        '& .rcw-client > .rcw-message-text': {
            fontSize: `${theme.typography.fontSize}px`,
            background: theme.palette.secondary.main,
            borderRadius: `${theme.shape.borderRadius}px !important`,
            color: theme.palette.text.primary
        },
        '& .rcw-response .rcw-message-text': { 
            fontSize: `${theme.typography.fontSize}px !important`, 
            background: theme.palette.primary.main,
            borderRadius: `${theme.shape.borderRadius}px !important`,
            color: theme.palette.text.primary
        },
        //// Adaptive card border radius as per theme
        '& .ac-container.ac-adaptiveCard': {
            borderRadius: `${theme.shape.borderRadius}px !important`,
            maxWidth: fullScreenMode ? '60vw' : 'calc(100% - 50px)',
            minWidth: smallScreen ? 'unset' : '225px',
            '& .ac-container.ac-adaptiveCard': {
                // borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px !important`,  
                maxWidth: 'unset'
            }
        },
        //// custom button css as quick-send one
        '& .ac-actionSet': {
            flexDirection: 'row !important',
            flexWrap: 'wrap !important',
            '& .ac-pushButton': {
                background: '#EFEFEF',
                borderRadius: '15px',
                border: `2px solid ${theme.palette.primary.main}`,
                cursor: 'pointer',
                padding: '5px 10px',
                outline: 0,
                maxWidth: '100%',
                flex: '0 1 !important'
            }
        }
    }
});

/// Declare prop types that are expected to component and default value
ThemedChatWidget.propTypes = {
    subtitle: PropTypes.string,
    fullScreenMode: PropTypes.bool.isRequired,
    headerVisibility: PropTypes.bool,
    stackQuickactionButtons: PropTypes.bool
};

ThemedChatWidget.defaultProps = {
    fullScreenMode: false,
    headerVisibility: true,
    stackQuickactionButtons: true
};

export default ThemedChatWidget
