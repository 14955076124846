const themeOptions = {
    palette: {
        primary: {
            main: '#008E5B', // "Kelly green",
            dark: '#1B4D4A', // "Prologis green"
            light: '#B7E59A'
        },
        secondary: {
            main: '#C4C4C4' // Light gray, form elements
        },
        error: {
            main: '#FE1818' // "Alerts and errors"
        },
        // warning: {},
        info: {
            main: '#2CB5E5' // "Links and highlights" (blue)
        },
        success: {
          main: '#71BE45' // "Light green highlight"
        },
        background: {
            paper: '#FFFFFF',
            default: '#EFEFEF',
        },
    },
    typography: {
        fontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif'
        ]
    }
};
  
export default themeOptions