import React from 'react'

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { GenericReducer, GenericReducerFunctionMap } from '../../pld-helpers';

const contentContext = React.createContext();
export function useAuthContext() {
    var context = React.useContext(contentContext);
    if (context === undefined) {
        throw new Error("useAuthContext must be used within an AuthProvider");
    }
    return context;
}

const dispatchContext = React.createContext();
export function useAuthDispatchContext() {
    var context = React.useContext(dispatchContext);
    if (context === undefined) {
        throw new Error("AuthDispatchContext must be used within an AuthProvider");
    }
    return context;
}
// #endregion


function LogAuthSettings(context, dispatch) {
    console.log("context", context)
    // console.log("dispatch", dispatch)
}

function Login(context, dispatch) {
    return new Promise((resolve, reject) => {
        acquireToken(context).then((response) => {
            // console.log("response", response)
            GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
                "accessToken": response.accessToken,
                "idToken": response.idToken,
                "idTokenClaims": response.idTokenClaims,
            })
            resolve();
        }).catch((error) => {
            console.log("error", error)
            reject(error)
        })
    })
}

function Logout(context, dispatch) {
    console.log("logout context", context)
    var currentAccount = context.msalInstance.getActiveAccount()
    context.msalInstance.logoutPopup({
        account: currentAccount
    })
    GenericReducerFunctionMap(dispatch).RemoveMultipleContextStateValues( 
        ["accessToken", "idToken", "idTokenClaims"])
}

// #region Helper Functions

// This function is for actually getting the token from Azure AD, use getToken
// to get a cached token if possible
function acquireToken(context) {
    const request = {
        ...context.loginRequest,
        account: context.accounts[0]
    }
    return new Promise(function (resolve, reject) {
        context.msalInstance.acquireTokenSilent(request).then((response) => {
            // console.log("response", response)
            resolve(response)
        }).catch((e) => {
            context.msalInstance.acquireTokenPopup(request).then((response) => {
                resolve(response)
            });
        }).catch((e) => {
            reject(e)
        });
    })
}

// This function returns a cached token if possible or uses the acquire token 
// flow to get a new token
function getToken(context) {
    return new Promise(function (resolve, reject) {
        if (isAuthenticated(context)) {
            resolve(context.accessToken)
        }
        else {
            acquireToken(context).then((response) => {
                resolve(response.accessToken)
            }).catch((e) => {
                reject(e)
            })
        }
    })
}

function getOrganizationId(context) {
    return context.configuration.msal_authority.split("/")[3]
}

function isAuthenticated(context) {
    if (context.accessToken === undefined) { return false }
    if (context.accessToken === "") { return false }
    if (context.idTokenClaims.exp < Date.now() / 1000) { return false }
    return true
}

function AuthenticatedCall(context, dispatch, url, method, body) {
    return new Promise((resolve, reject) => {
        getToken(context).then((token) => {
            var headers = {
                "auth": "Bearer " + token,
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
            var options = {
                method: method,
                // mode: 'cors', // 'no-cors', // no-cors, *cors, same-origin
                headers: headers,
            }
            if (body !== undefined) {
                options.body = JSON.stringify(body)
            }
            fetch(url, options)
            .then((response) => {
                //console.log("response pre processing", response)
                try {
                    let contentType = response.headers.get("Content-Type")
                    //console.log("contentType", contentType)
                    if (contentType === null) {
                        resolve(response.json())
                    }
                    else if (contentType.includes("application/json")) {
                        resolve(response.json())
                    }
                    else {
                        resolve(response.blob())
                    }
                } catch (error) {
                    // console.log("unable to parse response", error)
                    reject(error)
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
        .catch((error) => {
            reject(error)
        })
    })
}

function GetUser(context) {
    return GenericGraphCall(context, "/me")
}

function GetUserRoles(context) {
    return GenericGraphCall(context, "/me/appRoleAssignments?$filter=resourceId eq 1f29c3d5-4df9-4617-b67f-7f71aef0a4e8")
}

function GetProfilePicture(context) {
    return GenericGraphCall(context, "/me/photo/$value")
}

function GetOrganizationLogo(context) {
    let path = '/organization/' + getOrganizationId(context) + '/branding/localizations/default/bannerLogo'
    return GenericGraphCall(context, path)
}

function GetGroupMemberships(context) {
    return GenericGraphCall(context, "/me/memberOf")
}

function GenericGraphCall(context, path, method="GET", body={}) {
    return new Promise(function (resolve, reject) {
        getToken(context).then((token) => {
            callGraph(token, context.configuration.azure_graph_endpoint, method, path, body).then((response) => {
                resolve(response)
            }).catch((e) => {
                reject(e)
            })
        }).catch((e) => {
            reject(e)
        })
    })
}

function callGraph(accessToken, graphEndpoint, method = "GET", path = "/me", body = null) {
    const headers = new Headers({
        'Authorization': `Bearer ${accessToken}`
    });
    const options = {
        method: method,
        headers: headers
    };
    const fullPath = graphEndpoint + path;
    return new Promise(function (resolve, reject) {
        fetch(fullPath, options)
            .then((response) => {
                try {
                    let contentType = response.headers.get("Content-Type")
                    if (contentType.includes("application/json")) {
                        resolve(response.json())
                    }
                    else {
                        resolve(response.blob())
                    }
                } catch (error) {
                    // console.log("unable to parse response", error)
                    reject(error)
                }
            })
            .catch((error) => {
                reject(error)
            })
    })
}

// #endregion

export function useAuthFunctions() {
    const context = useAuthContext()
    const dispatch = useAuthDispatchContext()
    const authFunctions = {
        logSettings: () => LogAuthSettings(context, dispatch),
        login: () => Login(context, dispatch),
        logout: () => Logout(context, dispatch),
        isAuthenticated: () => isAuthenticated(context),
        getUser: () => GetUser(context),
        GetProfilePicture: () => GetProfilePicture(context),
        GetOrganizationLogo: () => GetOrganizationLogo(context),
        authenticatedCall: (url, method, body) => AuthenticatedCall(context, dispatch, url, method, body),
        getGroupMemberships: () => GetGroupMemberships(context),
        getUserRoles: () => GetUserRoles(context),
        GenericGraphCall: (path, method, body) => GenericGraphCall(context, path, method, body)
    }
    return authFunctions
}

export function AuthProvider({ children, ...rest }) {

    const configuration = rest.configuration || {}
    const loginRequest = {
        scopes: ["User.Read"]
    };

    // #region MSAL Config
    // TODO: Please Ravi look over this because I super-duper just stole it from your code. 
    // setup the msalInstance
    const msalInstance = new PublicClientApplication(getMSALConfig());
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();
    // Set the active account when a user logs in successfully
    msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const account = event.payload.account;
            msalInstance.setActiveAccount(account);
        }
    });
    const accounts = msalInstance.getAllAccounts();
    // #endregion

    function getMSALConfig() {
        const msalConfig = {
            auth: {
                clientId: configuration.msal_client_id,
                authority: configuration.msal_authority,
                redirectUri: window.location.origin + "/auth/msalresponse"
            },
            cache: {
                cacheLocation: "sessionStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            }
        };
        return msalConfig;
    }

    const [authContextState, authContextDispatch] = React.useReducer(GenericReducer, {
        msalInstance: msalInstance,
        msalConfig: getMSALConfig(),
        configuration: configuration,
        loginRequest: loginRequest,
        accounts: accounts,
    });

    return (
        <MsalProvider instance={msalInstance}>
            <contentContext.Provider value={authContextState}>
                <dispatchContext.Provider value={authContextDispatch}>
                    {children}
                </dispatchContext.Provider>
            </contentContext.Provider>
        </MsalProvider>
    );
}









