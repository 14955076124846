import React from "react";

import {
  useChatSettings,
  Message
} from "../..";
import {
  List, 
} from "../../../pld-ui"

export default function ConversationMessages(props) {
  const chatSettings = useChatSettings();
  var messages = chatSettings.placeholderMessages;

  const conversationContainerStyle = {
    display: 'flex',
    width: '100%',
    background: chatSettings.conversationContainerBackground,
    height: chatSettings.conversationContainerHeight,
    overflowY: 'scroll',
  };

  const conversationListStyle = {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  };

  return (
    <>
      {chatSettings.displayMessages && (
        <div style={conversationContainerStyle}>
          <List style={conversationListStyle}>
            {messages.map((message, index) => {
              return (
                <Message message={message} index={index} key={index}/>
              );
            })}
          </List>
        </div>
      )}
    </>

  );
}
