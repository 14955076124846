import React, { useState, useEffect } from "react";
import {
  Drawer
} from "@mui/material";
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";

import { ToggleButtons } from "../.."

export default function NavigationDrawer(props) {
  const anchor = props.anchor || "left";
  const drawerContent = props.drawerContent;
  const showToggle = (drawerContent !== undefined);
  var [isPermanent, ] = useState(false);

  const [isSidebarOpened, setSidebarOpen] = useState(false);

  const onCloseDrawer = (event) => {
    console.log(event)
    if (isSidebarOpened) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {

  }, []);

  return (
    <>
      {showToggle &&
        <>
          <ToggleButtons
            onClick={() => setSidebarOpen(!isSidebarOpened)}
            defaultState={isSidebarOpened}
            trueIcon={<MenuIcon />}
            falseIcon={<ArrowBackIcon />}
          />
          <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            anchor={anchor}
            open={isSidebarOpened}
            //onClose={onCloseDrawer}
            position="sticky"
            onClose={onCloseDrawer}
          >
            {drawerContent}
          </Drawer>
        </>
        
      }
    </>
  );
}
