import * as React from 'react';
import * as utils from '../../../utils'
import {
    LoadingTableBase,
    LoadingTableCell,
    TableCell, TableHead, TableRow, TableBody
} from '../../../library/pld-ui'
import {
    useAuthFunctions
} from '../../../library/pld-auth'

export default function MissedNLUTableV2(props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [missedNLUs, setMissedNLUs] = React.useState([])


    const processDataResult = React.useCallback((data) => {
        setMissedNLUs(data)
        setLoading(false)
    }, [setLoading, setMissedNLUs])

    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/nluresults`, "POST", {}).then((result) => {
            processDataResult(result)
        })
        // eslint-disable-next-line
    }, [])

    return (
        <LoadingTableBase loading={loading}>
            <TableHead>
                <TableRow>
                    <TableCell>NLU Event</TableCell>
                    <TableCell>Room</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>User Content</TableCell>
                    <TableCell>Response Message</TableCell>
                    <TableCell>Bot Identifier</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {missedNLUs.map((item, index) => (
                    <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {item.typeId.split(" ")[0]}
                        </TableCell>
                        <ConnectedNodeDetailCell
                            nodeId={item.id}
                            edgeType={"room_association"}
                            sourceOrTarget={"source"}
                        />
                        <ConnectedNodeDetailCell
                            nodeId={item.id}
                            edgeType={"timestamp_association"}
                            sourceOrTarget={"source"}
                        />
                        <ConnectedNodeDetailCell
                            nodeId={item.id}
                            edgeType={"user_content_association"}
                            sourceOrTarget={"source"}
                        />
                        <ConnectedNodeDetailCell
                            nodeId={item.id}
                            edgeType={"lex_response_message_association"}
                            sourceOrTarget={"source"}
                        />
                        <ConnectedNodeDetailCell
                            nodeId={item.id}
                            edgeType={"lex_bot_association"}
                            sourceOrTarget={"source"}
                        />
                    </TableRow>
                ))}
            </TableBody>
        </LoadingTableBase>
    );
}


export function ConnectedNodeDetailCell(props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [valueToDisplay, setValueToDisplay] = React.useState("")
    const attributeToDisplay = props.attributeToDisplay || "title"

    React.useEffect(() => {
        var request_body = {
            "nodeId": props.nodeId,
            "edgeType": props.edgeType,
            "sourceOrTarget": props.sourceOrTarget
        }
        const processDataResult = (data) => {
            // console.log("element data", data)
            if (data.length > 0) {
                setValueToDisplay(data[0][attributeToDisplay])
            }
            setLoading(false)
        }
        auth.authenticatedCall(
            `${utils.getApiEndpoint()}/admin/graph/getnodeconnectednodesofconnectiontype`, "POST", request_body)
            .then((result) => {
                processDataResult(result)
            })
        // eslint-disable-next-line
    }, [])


    return (
        <LoadingTableCell loading={loading}>
            {valueToDisplay}
        </LoadingTableCell>
    )
}

