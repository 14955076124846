import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";

import {
  SearchBar,
  DefaultStyles,
  NavigationDrawer,
} from "../../../pld-ui"
import {
  AuthenticatedProfileMenu,
  AuthenticatedOrganizationLogo,
} from "../.."


export default function Header(props) {
  const classes = DefaultStyles();

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <NavigationDrawer drawerContent={props.drawerContent} />
        <AuthenticatedOrganizationLogo />
        <Typography
          component="span"
          className={classes.title}
        >
          {props.title && <h1 className={classes.title} >{props.title}</h1>}
        </Typography>
        <SearchBar />
        <AuthenticatedProfileMenu />
      </Toolbar>
    </AppBar>
  );
}
