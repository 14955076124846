import React from "react";
import {
  Avatar, Badge, 
  ForumIcon, CloseIcon,
} from "../../../pld-ui";
import { useChatFunctions, useChatSettings } from "../..";

export default function Launcher(props) {
  const chatSettings = useChatSettings();
  const chatFunctions = useChatFunctions();

  const closedIcon = <ForumIcon />;
  const openIcon = <CloseIcon />;

  const isOpen = chatSettings.isOpen;
  const displayLoader = !chatSettings.isOpen || chatSettings.displayLauncherWhenOpen;

  const frameMargin = chatSettings.frameMargin;
  const avatarRadius = chatSettings.launcherRadius;
  const badgeContent = isOpen ? undefined : chatSettings.badgeContent;
  const badgeAnchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
  };

  const onLauncherClick = () => {
    chatFunctions.launcherSelected();
  }

  return (
    <>
      {displayLoader && (
    <div style={{
      position: 'absolute',
      bottom: frameMargin,
      right:frameMargin
    }}>
    <Badge 
      color="secondary"
      badgeContent={badgeContent}
      anchorOrigin={badgeAnchorOrigin}>
      <Avatar 
        sx={{width: avatarRadius, height: avatarRadius}}
        onClick={onLauncherClick}>
        {isOpen ? openIcon : closedIcon}
      </Avatar>
    </Badge>
    </div>
      )}
    </>
    
  );
}
