import React from 'react'

import * as utils from '../../../utils'

import {
    useAuthFunctions
} from '../../../library/pld-auth'
import {
    LineChart,
    DisplayCard,
    GridTileItem,
} from '../../../library/pld-ui'

export default function ChatStatisticsCard(props) {
    const auth = useAuthFunctions()
    const [uniqueVisitors, setUniqueVisitors] = React.useState([])
    const [userChatEvents, setUserChatEvents] = React.useState([])

    const [loading, setLoading] = React.useState(true)

    const processDataResult = (data) => {
        // Unique visitors
        var temp_unique_visitors = [];
        var temp_user_chat_events = [];
        for (var item in data) {
            temp_unique_visitors.push([data[item].alt_date_string, data[item].unique_rooms])
            temp_user_chat_events.push([data[item].alt_date_string, data[item].user_messages])
        }
        setUniqueVisitors(temp_unique_visitors)
        setUserChatEvents(temp_user_chat_events)
        // Once processing is done, display everything
        setLoading(false)
    }

    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/chatstatistics`, "POST", {}).then((result) => {
            processDataResult(result)
        })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <GridTileItem>
                <DisplayCard
                    loading={loading}
                    title="Unique Users">
                    <LineChart
                        title="Unique Users by Day"
                        itemName="Unique Users"
                        series={uniqueVisitors}
                    />
                </DisplayCard>
            </GridTileItem>
            <GridTileItem>
                <DisplayCard

                    loading={loading}
                    title="User Chat Events">
                    <LineChart
                        title="User Chat Events by Day"
                        itemName="User Chat Events"
                        series={userChatEvents}
                    />
                </DisplayCard>
            </GridTileItem>
        </>
    )
}

