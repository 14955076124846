import React from "react";
import { ChatProvider } from "../..";

export default function ChatBase(props) {
  return (
        <ChatProvider>
          {props.children}
        </ChatProvider>
  );
}
