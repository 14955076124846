import * as React from 'react';

import { useAuthFunctions } from '../../../library/pld-auth'
import { ChatWidget, ChatController } from '../../../library/PLD-Chat-Widget'
import { GridWrapper, GridButton, GridTileItem, DisplayCard } from '../../../library/pld-ui';

export default function NewWidgetTester(props) {
    const auth = useAuthFunctions()
    var chatController = ChatController() // React.useRef();

    React.useEffect(() => {
        auth.getUser().then((result) => {
            // console.log("gotten_user: ", result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <GridWrapper >
                <GridButton text="Toggle Chat Window" onClick={() => chatController.ToggleChatWindow()} />
                <GridButton text="Hide Header" onClick={() => chatController.hideHeader()} />
                <GridButton text="Show Header" onClick={() => chatController.showHeader()} />
                <GridButton text="Close Chat Window" onClick={() => chatController.CloseChatWindow()} />
                <GridButton text="Open Chat Window" onClick={() => chatController.OpenChatWindow()} />
                <GridButton text="Log Controller" onClick={() => chatController.LogController()} />
                <GridButton text="Close Buttons" onClick={() => chatController.setConfigValue("displayButtonInput",false)} />
                <GridButton text="Open Buttons" onClick={() => chatController.setConfigValue("displayButtonInput",true)} />
                <GridButton text="Placeholder" onClick={() => chatController.showHeader()} />
                <GridTileItem>
                    <ChatWidget chatController={chatController} format={"static"} />
            </GridTileItem>
            </GridWrapper>
            <ChatWidget chatController={chatController} />
        </>
    );
}

