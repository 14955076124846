import React, { forwardRef, useImperativeHandle } from 'react';

import {
  Launcher, ChatBase, Conversation, ConversationContainer, ChatController,
  useChatSettings, useChatFunctions
} from "../..";

// Pages
export default function ChatWidget(props) {
  var backupController = ChatController();
  var chatController = props.chatController || backupController;
  return (
    <ChatBase>
      <InnerChatWidget ref={chatController.ref} {...props} />
    </ChatBase>
  )
}

const InnerChatWidget = forwardRef((props, ref) => {
  const chatSettings = useChatSettings();
  const chatFunctions = useChatFunctions();
  const format = props.format || "bubble";
  useImperativeHandle(ref, () => ({
    log() { console.log("ChatWidget: ", chatSettings, chatFunctions) },
    ToggleChatWindow() { chatFunctions.launcherSelected(); },
    setConfigValue(key, value) { chatFunctions.setConfigValue(key, value) },
  }));
  return (
    <>
      {
        format === "bubble" && (
          <>
            <ConversationContainer>
              <Conversation />
            </ConversationContainer>
            <Launcher />
          </>
        )
      }
      {
        format === "static" && (
          <>
              <Conversation />
          </>
        )
      }
    </>
  );
})