
export { GenericReducer, GenericReducerFunctionMap } from "./GenericReducer";

export function log(message, logging_enabled = true) {
    if (!logging_enabled) { return }
    console.log(message)
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function checkNested(obj, level, ...rest) {
    if (obj === undefined) return false
    if (rest.length === 0 && obj.hasOwnProperty(level)) return true
    return checkNested(obj[level], ...rest)
}

export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function callIfFunction(func, input) {
    if (func) {
        if (isFunction(func)) {
            return func(input)
        }
    }
}

export function isPromise(p) {
    if (typeof p === 'object' && typeof p.then === 'function') {
        return true;
    }
    return false;
}


