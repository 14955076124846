
import { IconButton, Tooltip, ContentCopyIcon } from '../..';

export default function HoverContentWithClickCopy(props) {
    var copyValue = props.copyValue
    var hoverText = props.hoverText || copyValue
    var icon = props.icon || <ContentCopyIcon />
    return (
        <div>
            <Tooltip title={hoverText}>
            <IconButton
                onClick={() => {
                    navigator.clipboard.writeText(copyValue)
                }}
            >
                {icon}
            </IconButton>
        </Tooltip>
        </div>
        
    )
}