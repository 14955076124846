import * as React from 'react';

import { useAuthFunctions } from '../../../library/pld-auth'
import * as utils from '../../../utils'
import {
    SimplePicklist, 
} from '../../../library/pld-ui'

export default function GraphHelper(props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [edgeTypes, setEdgeTypes] = React.useState([])
    const [nodeTypes, setNodeTypes] = React.useState([])
    const [selectedEdgeTypes, setSelectedEdgeTypes] = React.useState([])
    const [selectedNodeTypes, setSelectedNodeTypes] = React.useState([])

    const handleEdgeTypeChange = (event) => {
        setSelectedEdgeTypes(event.target.value);
    };

    const handleNodeTypeChange = (event) => {
        setSelectedNodeTypes(event.target.value);
    };

    const processAskedQuestionsReult = React.useCallback((data) => {
        // console.log("graphhelpersetup: ", data)
        setEdgeTypes(data.edge_types || [])
        setNodeTypes(data.node_types || [])
        setLoading(false)
    }, [])

    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/graphhelpersetup`, "POST", {}).then((result) => {
            processAskedQuestionsReult(result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            {loading ? <p>Loading...</p> : (<>
            <SimplePicklist 
                title="Edge Types" 
                onChange={handleEdgeTypeChange}
                options={edgeTypes} 
                selectedOptions={selectedEdgeTypes} />
            <SimplePicklist 
                title="Node Types" 
                onChange={handleNodeTypeChange}
                options={nodeTypes} 
                selectedOptions={selectedNodeTypes} />
            </>)}
        </>
    );
}
