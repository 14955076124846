import React from "react";

import {
  Grid,
  Typography,
  Link
} from "@mui/material";

import { DefaultStyles } from "../..";

export default function Copyright(props) {

  const classes = DefaultStyles();
  return (
    <>
      <Typography variant="body2" className={classes.copyright} component={'span'}>
        Copyright © {new Date().getFullYear()} Prologis, Inc. All rights reserved.
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ color: '#A7A9AB', paddingTop: '10px' }}>
          <Grid item xs={5}>
            <Link
              variant="body2"
              color="inherit"
              href="https://www.prologis.com/terms-of-use"
              target="_blank"
              rel="noopener"
            >
              Terms of use
            </Link>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5} style={{ textAlign: 'right' }}>
            <Link
              variant="body2"
              color="inherit"
              href="https://www.prologis.com/privacy-policy"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </Link>
          </Grid>
        </Grid>
      </Typography>
    </>
  )
}