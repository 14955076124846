import React from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { DefaultStyles } from "../..";

// TODO: This needs to actually provide input and feedback to the search

export default function NavigationDrawerContentBuilder(props) {
  const classes = DefaultStyles();
  const drawerContent = props.drawerContent;

  React.useEffect(() => {

  }, []);

  return (
    <>
      <List className={classes.sidebarList}>
        {drawerContent.map((item, index) => (
          <ListItem onClick={item.conClick} key={index}>
            <Button onClick={item.onClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
            </Button>
          </ListItem>
        ))}
      </List>
    </>
  );
}
