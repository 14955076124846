import React, { useEffect, useState } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import ChatWidgetV2 from '../components/ChatWidgetV2';

// eslint-disable-next-line
export default function (props) {
    const [botContext, setBotContext] = useState({})

    const on_context_loaded = React.useCallback( (context) => {
        setBotContext(context);
    }, [setBotContext]);

    const getContext = React.useCallback( () => {
        let context = botContext;
        // console.log("botcontext", context)
        context.page = "msteamsapp"
        return context;
    }, [botContext]);

    const additionalButtons = []

    useEffect(() => {
        // console.log(window)
        try {
            microsoftTeams.initialize();
            // console.log("teams initialized");
            microsoftTeams.getContext(on_context_loaded)
        } catch (error) {
            // console.log("error getting teams context")
            // console.log(error);
        }
        // eslint-disable-next-line
    }, []);


    return (
        <div style={{ width: '100%' }}>
            <ChatWidgetV2
                defaultOpen={true}
                fullScreenMode={true}
                showCloseButton={false}
                additionalButtons={additionalButtons}
                getContext={getContext}
                headerVisibility={false}
            />
        </div>
    )

}
