import {
    Table, TableCell, TableHead, TableRow, TableBody, TableContainer,
    Skeleton,
} from '../..'

export default function SkeletonCard(props) {
    const columns = props.columns || 3
    const rows = props.rows || 3

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {Array.from(Array(columns).keys()).map((item, index) => (
                            <TableCell key={index}>
                                <Skeleton variant="rectangular" height={40} />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.from(Array(rows).keys()).map((item, index) => (
                        <TableRow key={index}>
                            {Array.from(Array(columns).keys()).map((item, index) => (
                                <TableCell key={index}>
                                    <Skeleton variant="rectangular" height={30} />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

