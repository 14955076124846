import React from 'react'
import { Grid } from '../..'

export default function GridTileItem(props) {
    return (
        <Grid item lg={3} md={4} sm={6} xs={12}>
            {props.children}
        </Grid>
    )
}

