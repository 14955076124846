import React from 'react'

import * as utils from '../../../utils'

import {
    useAuthFunctions
} from '../../../library/pld-auth'
import {
    LineChart,
    DisplayCard,
    GridTileItem,
} from '../../../library/pld-ui'

export default function ChatStatisticsCard(props) {
    const auth = useAuthFunctions()
    const [tracerlink_events, setTracerlinkEvents] = React.useState([])
    const [tracerlink_type_events, setTracerlinkTypeEvents] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const processDataResult = (data) => {
        // console.log('data', data)
        var temp_tracerlink_events = [];
        var temp_tracerlink_type_events = [];
        for (var item in data) {
            temp_tracerlink_events.push([data[item].alt_date_string, data[item].tracelink_events])
            for (var target in data[item].targets) {
                let target_type_name = target
                // console.log('target_type_name', target_type_name)
                var found = false
                for (var target_type in temp_tracerlink_type_events) {
                    if (temp_tracerlink_type_events[target_type].name === target) {
                        temp_tracerlink_type_events[target_type].data.push([data[item].alt_date_string, data[item].targets[target]])
                        found = true
                    }
                }
                if (!found) {
                    temp_tracerlink_type_events.push({
                        name: target,
                        data: [[data[item].alt_date_string, data[item].targets[target]]]
                    })
                }
            }
        }
        setTracerlinkEvents(temp_tracerlink_events)
        setTracerlinkTypeEvents(temp_tracerlink_type_events)
        // Once processing is done, display everything
        setLoading(false)
    }

    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/tracelinkstatistics`, "POST", {}).then((result) => {
            processDataResult(result)
        })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <GridTileItem>
                <DisplayCard
                    loading={loading}
                    title="Tracerlink Events by Day">
                    <LineChart
                        title="Tracerlink Events by Day"
                        itemName="Tracerlink Events"
                        series={tracerlink_events}
                    />
                </DisplayCard>
            </GridTileItem>
            <GridTileItem>
                <DisplayCard
                    loading={loading}
                    title="Tracerlink Events by Day/Type">
                    <LineChart
                        title="Tracerlink Events by Day/Type"
                        multiSeries={tracerlink_type_events}
                    />
                </DisplayCard>
            </GridTileItem>
        </>
    )
}

