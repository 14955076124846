import * as React from 'react';

import { useAuthFunctions } from '../../../library/pld-auth'
import * as utils from '../../../utils'
import {
    LoadingTableBase,
    HoverContentWithClickCopy,
    TableCell, TableHead, TableRow, TableBody
} from '../../../library/pld-ui'

export default function AskedQuestions(props) {
    const auth = useAuthFunctions()
    const [loading, setLoading] = React.useState(true)
    const [askedquestions, setAskedQuestions] = React.useState([])

    const processAskedQuestionsReult = React.useCallback((data) => {
        // console.log("askedquestions: ", data)
        if (Array.isArray(data)) {
            // console.log("It is an Array")
            setAskedQuestions(data)
        }
        setLoading(false)
    }, [])

    React.useEffect(() => {
        auth.authenticatedCall(`${utils.getApiEndpoint()}/admin/getaskedquestions`, "POST", {}).then((result) => {
            processAskedQuestionsReult(result)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <LoadingTableBase loading={loading}>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Room</TableCell>
                        <TableCell>Origin</TableCell>
                        <TableCell>Statement</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {askedquestions.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell >
                                <HoverContentWithClickCopy copyValue={item.eventId}/>
                            </TableCell>
                            <TableCell>
                                <HoverContentWithClickCopy copyValue={item.roomId}/>
                            </TableCell>
                            <TableCell>
                                {item.context.origin}
                            </TableCell>
                            {
                            <TableCell>
                                {item.events.map((item, index) => (
                                    item.type === "text" ? item.content : "data_event"
                                ))}
                            </TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </LoadingTableBase>
        </>
    );
}
