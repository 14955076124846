import React from "react";

import { useChatSettings } from "../..";

export default function ConversationContainer(props) {
  const chatSettings = useChatSettings();

  const isOpen = chatSettings.isOpen;

  const frameMargin = chatSettings.frameMargin;
  const avatarRadius = chatSettings.launcherRadius;
  const conversationContainerHeight = chatSettings.conversationContainerHeight;
  const conversationContainerWidth = chatSettings.conversationContainerWidth;
  const conversationContainerBackground = chatSettings.conversationContainerBackground;
  const borderRadius = chatSettings.borderRadius;
  const displayLauncherWhenOpen = chatSettings.displayLauncherWhenOpen;

  const containerBottom = 2 * frameMargin + (avatarRadius * (displayLauncherWhenOpen ? 1 : 0));

  return (
    <>
      {isOpen && (
      <div style={{
        borderRadius: borderRadius,
        background: conversationContainerBackground,
        position: 'absolute',
        height: conversationContainerHeight,
        width: conversationContainerWidth,
        bottom: containerBottom,
        right: frameMargin
      }}>
        {props.children}
      </div>
      )}
    </>

  );
}
