import React from "react";

import {
  useChatSettings,
} from "../..";
import {
  Card,
  ListItem
} from "../../../pld-ui"

export default function Message(props) {
  var message = props.message;
  var index = props.index;
  var source = message.source || "user";
  var messageType = message.type || "text";

  const messageContainerStyle = {
    display: 'flex',
    alignSelf: source === "user" ? "flex-end" : "flex-start",
    flexdirection: "row",
  };

  return (
      <div key={index} style={messageContainerStyle}>
        <ListItem key={index} style={messageContainerStyle}>
          {messageType === "text" && (<TextMessage message={message} />)}
        </ListItem>
      </div>
  );
}

export function TextMessage(props) {
  const chatSettings = useChatSettings();
  var message = props.message;
  var content = message.content;
  var source = message.source || "user";
  var isUserMessage = source === "user";
  var background = isUserMessage ? chatSettings.messageBackgroundUser : chatSettings.messageBackgroundSystem;

  const messageStyle = {
    display: 'flex',
    paddingLeft: '5px',
    paddingRight: '5px',
    background: background,
    color: chatSettings.messageTextColor,
    py: '0px',
    my: '0px',
    pb: '0px',
    marginBlockStart: '0px',
    maxWidth: chatSettings.conversationContainerWidth*chatSettings.messageMaxWidthMultiplier,
  };

  return (
    <Card style={messageStyle}>
      <p>{content}</p>
    </Card>
  );
}
