import React from "react";
import {
  IconButton,
  Avatar
} from "@mui/material";
import {
  Person as AccountIcon,
} from "@mui/icons-material";

import { DefaultStyles } from "../../../pld-ui";
import { callIfFunction } from "../../../pld-helpers";
import { useAuthFunctions } from "../../../pld-auth";

export default function AuthenticatedAvatar(props) {
  const classes = DefaultStyles();
  const auth = useAuthFunctions()
  const [profilePhoto, setProfilePhoto] = React.useState(null);

  const onClick = (args) => {
    callIfFunction(props.onClick, args);
  }

  React.useEffect(() => {
    auth.GetProfilePicture().then((result) => {
      var objectURL = URL.createObjectURL(result);
      setProfilePhoto(objectURL)
    }).catch((error) => {
      // console.log("error", error)
    })
    // Do not add auth as a dependency. This will cause an infinite loop.
    // eslint-disable-next-line
  }, []);

  return (
    <IconButton
      aria-haspopup="true"
      color="inherit"
      className={classes.headerMenuButton}
      aria-controls="profile-menu"
      onClick={e => onClick(e)}
      size="large">
      {
        profilePhoto ? (
          <Avatar src={profilePhoto} />
        ) : (
          <AccountIcon classes={{ root: classes.headerIcon }} />
        )}
    </IconButton>

  );
}
